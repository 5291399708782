import React, {
	Component
} from 'react'
import {
	Avatar,
	Input,
	Tooltip,
	Card
} from 'antd';
import {
	InfoCircleOutlined,
	UserOutlined,
	LockOutlined,
	StarTwoTone
} from '@ant-design/icons';
import client from './ykid.png';
import logo from './logo.png';
import transfer from './transfer.svg'
import './App.css';
// import clsx from 'clsx';
// import { makeStyles, createStyles, withTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {
	checkInput
} from './Tools';
import Button from '@material-ui/core/Button';
import ReactCountdownClock from 'react-countdown-clock';

import 'antd/dist/antd.css';
import './login.css';

const useStyles = {
	container: {
		display: 'flex',
		flexWrap: 'warp',
	},
	button: {
		maxWidth: 600,
		minWidth: 300,
		maxHeight: 66,
		minHeight: 50,
		marginTop: 30,
		borderRadius: 66,
	},
	getcode: {
		marginLeft: -90,
		minWidth: 36,
		marginTop: 34
	},
	clock: {
		//marginLeft: -48,
		//position:'absolute',
		float: 'right',
		marginTop: 50,
		marginLeft: -50
	},
	codebox: {
		display: 'flex',
		flexWrap: 'warp',
		alignitems: 'center',
		minWidth: 300,
		margin: 0,
		justifycontent: 'center'
	},
	textField: {
		maxWidth: 600,
		minWidth: 300,
		marginTop: '3rem',
		//borderRadius: 26,
		backgroundColor: '#ccc',
	},
	dense: {
		marginTop: 20,
	},
	menu: {
		width: 200,
	},
};
//const baseurl = 'http://oauth.yiqiankeji.net/'; //'http://uac.ynyqkj.net/; //'http://oauth.yiqiankeji.net/';//http://auth.yiqiankeji.net/
const baseurl = 'http://ouath.yiqiankeji.net/';
var APPicon = require('./ykid.png');
const authorurl = 'api/v1/UserControl/LoginAuth';
class APP extends Component {
	constructor(props) {
		super(props)
		this.state = {
			phone: '',
			pwd: '',
			Code: '',
			count: false
		}
		this.Reg = this.Reg.bind(this);
		this.filter = this.filter.bind(this);

		this.data = {};
		this.ip = "192.168.1.1";
	}

	componentWillMount() {
		this.data = this.filter(window.location.search);
		if (this.data.icon) {
			APPicon = this.data.icon;
		}

	}

	componentDidMount() {
		this.data = this.filter(window.location.search);
		console.log(document.referrer);
	}

	filter(str) {
		if (!str) {
			return ''
		}
		const obj = {};
		decodeURI(str).substr(1).split('&').map(item => {
			let arr = item.split('=');
			obj[arr[0]] = arr[1]
		})
		return obj
	}

	GetQueryString(name) {
		var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
		var r = window.location.search.substr(1).match(reg);
		if (r != null) return unescape(r[2]);
		return null;

	}

	componentWillUnmount() {

	}

	Reg() { //传回用户信息
		//let formData = new FormData();
		if (this.state.phone == '') {
			alert('请输入手机号码、账号、邮箱');
			return;
		}
		if (this.state.pwd == '') {
			alert('请输入密码');
			return;
		}

		let regiest = {
			account: this.state.phone,
			pwd: this.state.pwd,
			clientId: this.data.clientid,
			userId: this.data.userid
		};

		let options = {
			method: "POST", // 请求方式
			body: JSON.stringify(regiest), // 请求体
			headers: { // 请求头
				'Access-Control-Allow-Origin': '*',
				'Accept': 'text/plan',
				'Content-Type': 'application/json'
			}
		}
		fetch(baseurl + authorurl, options)
			.then((response) => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				} else {
					alert("授权失败！");
					return response.text();
				}
			})
			.then((data) => {
				console.log(data);
				if (data.success == true) {
					alert('恭喜您，授权成功!');
					setTimeout(() => {
						window.location.href = document.referrer;
					}, 2000)

					// options.body = JSON.stringify(data)
					// fetch(this.data.callback, options).then((response) => {
					// 	if (response.status >= 200 && response.status < 300) {
					// 		return response.json();
					// 	} else {
					// 		alert("回调失败！");
					// 		return response.text();
					// 	}
					// }).then((data) => {
					// 	alert(JSON.stringify(data));

					// }).catch((error) => {
					// 	alert(error);
					// });
				} else {
					alert(data.msg);
				}
			})
			.catch((error) => {
				// console.log('error')
				console.log(error)
			});
	}

	TextChange(name, event) {
		const num = /^[0-9]*$/;
		switch (name) {
			case 'acc':
				this.setState({
					phone: event.target.value
				})
				break;
			case 'pwd':
				this.setState({
					pwd: event.target.value
				})
				break;
			case 'Code':
				if (!num.test(event.target.value)) {
					alert('请输入正确的验证码');
					return;
				}
				this.setState({
					Code: event.target.value
				})
				break;
			default:
				break;
		}
		// console.log(event);
		// console.log(event.target.value);
	};

	render() {

		return (
			<div className="App" >
				<header className="App-header" >
					<div style={{display:"flex",justifyContent:"",alignItems:"center"}} >
						<div className="divicon" >
							<Avatar size={88}
								alt='auth'
								src={require('./logo.png')} />
							<b className="iconb" > 易乾科技 </b>
							<b className="iconb" style={{ marginTop: '0' }} > 智慧支付平台 </b>
						</div>
						<div className="divicon" >
							<b className="iconb" > 应用授权 </b>
							<img src={transfer}
								className="App-logo"
								alt="logo" />
							<b className="iconb" > 授权应用： 用户标识、 名称 </b>
						</div>

						<div className="divicon" >
							<Avatar size={88} alt='auth' src={APPicon} />
							<b className="iconb" > {this.data.nick} </b>
						</div>
					</div>
						<div style={{ display: 'flex', flexDirection: 'column' }} >
							<div style={{
								padding: '0.5rem'
							}} >
								<Input className='input'
									size='large'
									allowClear={true}
									placeholder={
										"请输入用户名/手机号/邮箱"
									}
									onChange={
										(u) => {
											this.TextChange('acc', u)
										}
									}
									onPressEnter={
										() => this.Logins
									}
									prefix={< UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
									suffix={<Tooltip title={this.state.usenerror} >
										<InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /> </Tooltip>} />
							</div>
							<div style={{ padding: '0.5rem' }} >
								<Input.Password className='input'
									size='large'
									allowClear={true}
									type='password'
									onChange={
										(p) => {
											this.TextChange('pwd', p)
										}
									}
									onPressEnter={
										() => this.Logins
									}
									placeholder={
										"请输入密码"
									}
									prefix={< LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
									suffix={
										<Tooltip title={
											this.state.pwderror
										} >
											<InfoCircleOutlined type="info-circle"
												style={
													{
														color: 'rgba(0,0,0,.45)'
													}
												}
											/>
										</Tooltip>} />
							</div>
						</div>
						<a target="_blank"
							style={{ color: 'grey', fontSize: '1rem' }}
							href={baseurl + 'Home'} > 易乾智慧支付平台
										</a>
						<Button variant="contained"
							color="primary"
							style={useStyles.button}
							onClick={this.Reg} >
							授权
						</Button>
                        <div class="bottom-info">
                            <span style={{ marginRight: '10px' }}>云南易乾科技有限公司</span>
                            <a href='https://beian.miit.gov.cn/#/Integrated/index' target="_blank" style={{color: '#333'}}>备案号:滇ICP备17004479号</a>
                        </div>
				</header>
			</div>
		);
	}
}

APP.propTypes = {

}

export default APP
